import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo_Nfdi4Energy from '../../../assets/img/sandbox_logos/logo_NFDI4ENERGY.svg';

const useStyles = makeStyles((theme) => ({
    text: {
        [theme.breakpoints.down('xl')]: {
            display: 'none',
        },
    },
}));

const Nfdi4EnergyLogo = () => {
    const classes = useStyles();
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="body2" className={classes.text}>
                Developed within
            </Typography>
            <img src={logo_Nfdi4Energy} width="165%" height="35%" />
        </Box>
    );
};

export default Nfdi4EnergyLogo;
