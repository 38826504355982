const makeDiff = async (ontUrl) => {
  const queryString = new URLSearchParams({ url: ontUrl }).toString();
  const response = await fetch(
    `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs?${queryString}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );

  if (response.ok) {
    return await response.text();
  }

  const errorData = await response.text();
  throw new Error(errorData.message || "Failed to create ontology diff.");
};

const updateDiffs = async (ontUrl, datetime) => {
  const queryString = new URLSearchParams({ url: ontUrl, datetime: datetime }).toString();
  const response = await fetch(
      `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/ondet/sdiffs/update?${queryString}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      },
  );

  if (response.ok) {
    return await response.text();
  }

  const errorData = await response.text();
  throw new Error(errorData.message || "Failed to create ontology diff.");
};

export const ondetService = {
  ondetDiff: async (url) => {
    return await makeDiff(url);
  },
  ondetUpdateDiffs: async (url, datetime) => {
    return await updateDiffs(url, datetime);
  },
};
