import MaterialReactTable from "material-react-table";
import React, {useEffect, useState} from 'react';
import {Typography, Grid, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export const PIDChecker = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_PID_BACKEND_URL}/read-ontology-iri-status/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const jsonData = await response.json();
                console.log(jsonData)
                if (jsonData.results && jsonData.results.length > 0) {
                    const lastElement = jsonData.results[jsonData.results.length - 1];
                    setData(lastElement);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // Flatten the data
    const flattenedData = data.ontology ? data.ontology.flatMap(ont => ({
        ontologyId: ont.ontologyId,
        details: ont.ontologyDetails,
        numberOfIRI: ont.totalNumberOfIRI,
        numberOfDownIRI: ont.ontologyDetails.length,
        ontologyURL: ont.ontologyFileLocation
    })) : [];

    const columns = [
        { header: 'Ontology ID', accessorKey: 'ontologyId' },
        { header: 'Number of IRI', accessorKey: 'numberOfIRI' },
        { header: 'Number of Down IRI', accessorKey: 'numberOfDownIRI' },
    ];


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={11}>
                <Typography variant="h5" gutterBottom textAlign="center">
                    PID Checker Service
                </Typography>
                <Typography variant="body2" gutterBottom style={{marginBottom: '1rem'}}>
                    The PID Checker service is designed to verify the resolvability and unresolvability of IRI (Internationalized Resource Identifier) statuses for terms within the NFDI4Energy project's ontologies. This service determines whether the IRIs associated with these terms are available or not, ensuring that all ontology terms are properly accessible.
                </Typography>
                <MaterialReactTable
                    columns={columns}
                    enableRowExpansion={true}
                    initialState={{ pagination: { pageSize: 5 } }}
                    enableColumnResizing={true}
                    autoResetPageIndex={false}
                    enableStickyHeader={true}
                    data={flattenedData}
                    renderDetailPanel={({ row }) => (
                        <Box sx={{ padding: '1rem'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>IRI</TableCell>
                                        <TableCell>Last Time Checked</TableCell>
                                        <TableCell>Server Status</TableCell>
                                        <TableCell>Term Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.original.details.map((detail, index) => {
                                        const iri = detail?.iri;
                                        const time =  detail?.lastTimeChecked;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <a href={iri} target="_blank" rel="noreferrer">{iri}</a>
                                                </TableCell>
                                                <TableCell>{formatDate(time)}</TableCell>
                                                <TableCell>{detail?.serverStatus}</TableCell>
                                                <TableCell>{detail?.termName}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                />
            </Grid>
        </Grid>
    );
};
